import * as React from 'react';

interface CustomSwitchProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  customColor?: string;
  disabled?: boolean;
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({
  checked,
  onChange,
  label,
  customColor = '#007bff', // Default to Bootstrap's primary color if no color is provided
  disabled = false,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <div
        style={{
          position: 'relative',
          width: '30px', // Smaller width
          height: '15px', // Smaller height
          backgroundColor: checked ? customColor : '#ccc',
          borderRadius: '15px', // Adjusted for smaller size
          transition: 'background-color 0.4s',
          cursor: disabled ? 'not-allowed' : 'pointer',
          marginRight: '8px', // Smaller space between switch and label
        }}
        onClick={!disabled ? onChange : undefined}
      >
        <div
          style={{
            position: 'absolute',
            top: '1px',
            left: checked ? '15px' : '1px', // Adjusted position based on size
            width: '13px', // Smaller knob size
            height: '13px',
            backgroundColor: 'white',
            borderRadius: '50%',
            transition: 'left 0.4s',
          }}
        />
      </div>
      <label style={{ margin: 0 }}>{label}</label>
    </div>
  );
};
