import * as React from 'react';
import { useEffect, useState } from 'react';
import { AppSchoolsUser2 } from '../AppSchools2';
import { getSchoolUserProfile } from '../../../services/schoolUser/schools';
import './SchoolAppTopNavigation2.css';

interface Props {
  user: AppSchoolsUser2;
  onLogoutClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  history: any;
}

export const SchoolAppTopNavigation2: React.FunctionComponent<Props> = props => {
  const { user, onLogoutClick } = props;
  const [userFirstName, setUserFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSchoolUserProfile(user)
      .then(schoolUser => {
        setUserFirstName(schoolUser.firstName);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching user profile:', error);
      });
  }, [user]);

  if (isLoading) {
    return null; // or a loading spinner, if you prefer
  }

  return (
    <nav className="schoolAppTopNav">
      <div className="navbar-left">
        <a href="/">
          <img className="appLogo" src="/dist/images/icon/bentleyLogo.png" alt="App Logo" />
          <span className="logo-caption">BENTLEY PHOTOGRAPHIC SCHOOLS PORTAL</span>
        </a>
      </div>
      <div className="navbar-right userMenu ml-auto">
        <div className="userDetails">
          <div className="userName">
            <img className="userNameIcon" src="/dist/images/icon/schoolUserName.svg" alt="User Icon" />
            {userFirstName} {/* Only display the user's first name */}
          </div>
          <div className="logoutContainer">
            <div className="logout" onClick={onLogoutClick}>
              Sign Out
              <img className="logoutIcon" src="/dist/images/icon/logout.svg" alt="Logout Icon" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

SchoolAppTopNavigation2.displayName = 'SchoolAppTopNavigation2';
