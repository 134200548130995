import * as React from 'react';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import * as propz from 'propz';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { School, SchoolSchoolType } from 'Src/models/schools';
import {
  addSchool,
  assignSchoolType,
  deleteSchool,
  getAllSchools,
  getSchools,
  getSchoolsCount,
  unassignSchoolType,
  updateSchool,
} from 'Src/services/superadmin/schools';
import { SchoolForm } from './SchoolForm/SchoolForm';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import { SchoolType } from '../../../../../../models/schoolTypes';
import { AssignSchoolTypeForm } from './AssignSchoolTypeForm/AssignSchoolTypeForm';
import { getAllSchoolTypes } from '../../../../../../services/superadmin/schoolTypes';
import { getSchoolTypes } from '../../../../../../helpers/accessor';
import { getSelectOptionForSchoolTypes } from '../../../../../../helpers/select';
import { DeleteSchoolTypeForm } from './DeleteSchoolTypeForm/DeleteSchoolTypeForm';
import { SchoolVoucherForm } from './SchoolVoucherForm/SchoolVoucherForm';
import { VOUCHER_DISCOUNT_TYPE } from '../../../../../../consts/voucher';
import { addVoucher } from '../../../../../../services/superadmin/vouchers';
import { addOffer } from '../../../../../../services/superadmin/offers';
import { SchoolOfferForm } from './SchoolOfferForm/SchoolOfferForm';
import { searchFunctionSchools, getName } from '../../../../../../helpers/autocomplete';
import { convertToFilterWithPlus } from '../../../../../../helpers/table';
import { LIMIT } from '../../../../../../consts/table';
import { TABS } from '../../../../../../consts/common';
import { GenericSummary } from '../../../../../../components/GenericSummary/GenericSummary';
import { Button } from '../../../../../../components/Button/Button';
import { ResizablePanel } from '../../../../../../components/ResizablePanel/ResizablePanel';
import { SCHOOLS_TABS } from '../../../../../../consts/schools';
import { SchoolSummary } from './SchoolSummary/SchoolSummary';
import { getAllSchoolForms } from '../../../../../../services/superadmin/schoolForms';
import { SchoolFormsSummary } from './SchoolFormsSummary/SchoolFormsSummary';
import { getAllStudents, getStudents } from '../../../../../../services/superadmin/students';
import { SchoolStudentSummary } from './SchoolStudentsSummary/SchoolStudentSummary';
import { SchoolUploadStudentsForm } from './SchoolUploadStudentsForm/SchoolUploadStudentsForm';
import { getAllSchoolUsers } from '../../../../../../services/superadmin/schoolUsers';
import { SchoolUserSummary } from './SchoolUserSummary/SchoolUserSummary';
import { getAllSchoolDetailsChangeLogs } from '../../../../../../services/superadmin/schoolDetailsChangeLog';
import { SchoolDetailsChangeLogSummary } from './SchoolDetailsChangeLogSummary/SchoolDetailsChangeLogSummary';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Reference',
    field: 'code',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['code'],
  },
  {
    text: 'Parent code',
    field: 'parentCode',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['parentCode'],
  },
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.AUTOCOMPLETE,
    accessor: ['name'],
  },
  {
    text: 'Town',
    field: 'city',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['city'],
  },
  {
    text: 'Type',
    field: 'schoolTypes',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getSchoolTypes,
  },
];

interface State {
  items: School[];
  currentPage: number;
  selectedItems: School[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  currentTabIndex: number;
  isSchoolFormModalOpen: boolean;
  isAssignSchoolTypeModalOpen: boolean;
  isDeleteSchoolTypeModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isUploadStudentsFormModalOpen: boolean;
  resultUploadText: string;
  isGoodSendingModalOpen: boolean;
  schoolTypes: SchoolType[];
  isErrorModalOpen: boolean;
  errorMessage: string;
  isVoucherFormModalOpen: boolean;
  isOfferFormModalOpen: boolean;
  autocompleteDefaultTexts: any;
  autocompleteSearchFunctions: any;
  autocompleteGetElementsTitles: any;

  schoolForms: any[];
  schoolStudents: any[];
  schoolUsers: any[];
  schoolDetailsChangeLogs: any[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Schools extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      currentTabIndex: 0,
      isSchoolFormModalOpen: false,
      isAssignSchoolTypeModalOpen: false,
      isDeleteSchoolTypeModalOpen: false,
      isConfirmationModalOpen: false,
      isUploadStudentsFormModalOpen: false,
      resultUploadText: '',
      isGoodSendingModalOpen: false,
      schoolTypes: [],
      isErrorModalOpen: false,
      isVoucherFormModalOpen: false,
      isOfferFormModalOpen: false,
      errorMessage: '',
      autocompleteDefaultTexts: {
        name: '',
      },
      autocompleteSearchFunctions: {
        name: this.getSchools,
      },
      autocompleteGetElementsTitles: {
        name: getName,
      },

      schoolForms: [],
      schoolStudents: [],
      schoolUsers: [],
      schoolDetailsChangeLogs: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    return searchFunctionSchools(user, text);
  };

  setAdditionalItems() {
    const { user } = this.props;
    const promises = [getAllSchoolTypes(user)];

    return Promise.all(promises).then(([schoolTypes]) => {
      this.setState({
        schoolTypes: schoolTypes,
      });

      return true;
    });
  }

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getSchools(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];
    return Promise.all(promises).then(([countObj, items]) => {
      const schoolId = propz.get(location, ['state', 'schoolId']);
      if (typeof schoolId !== 'undefined') {
        const school = items.find(item => item.id === schoolId);
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          selectedItems: [school],
          autocompleteDefaultTexts: {
            name: filters.name,
          },
        });
        const state = { ...(history.location.state as any) };
        delete state.schoolId;
        history.replace({ ...history.location, state });
      } else {
        this.setState({
          currentPage: page,
          sortDirection: sortDirection as TABLE_SORT_DIRECTION,
          sortColumnsName: sortColumnsName,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: items,
          total: countObj.count,
          basePath: history.location.pathname,
          isLoading: false,
          autocompleteDefaultTexts: {
            schoolName: filters.schoolName,
          },
        });
      }
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchools(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onTableAutocompleteFilterChange = (item, filterField: string): void => {
    const filterValue = item[filterField];
    const filters = this.state.filters;

    let nextFilters = { ...filters };

    nextFilters = {
      ...nextFilters,
      [filterField]: filterValue,
    };

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&'),
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
      autocompleteDefaultTexts: {
        name: '',
      },
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: '',
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onCreateSchoolClick = () => {
    this.setState({
      isSchoolFormModalOpen: true,
    });
  };

  onCreateVoucherClick = () => {
    this.setState({
      isVoucherFormModalOpen: true,
    });
  };

  onCancelCreateVoucherButtonClick = () => {
    this.setState({
      isVoucherFormModalOpen: false,
    });
  };

  onEditSchoolClick = () => {
    this.setState({
      isSchoolFormModalOpen: true,
    });
  };

  onDeleteSchoolClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    Promise.all(selectedItems.map(selectedItem => deleteSchool(user, selectedItem.id))).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isSchoolFormModalOpen: false,
    });
  };

  onCancelAssignSchoolTypeModalButtonClick = () => {
    this.setState({
      isAssignSchoolTypeModalOpen: false,
    });
  };

  onCancelDeleteSchoolTypeModalButtonClick = () => {
    this.setState({
      isDeleteSchoolTypeModalOpen: false,
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
    });
  };

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    if (selectedItems.length === 1) {
      const schoolUpdated = selectedItems[0];
      const { id } = schoolUpdated;
      const schoolTypes = propz.get(schoolUpdated, ['schoolTypes'], []);

      const { ...rest } = data;
      const dataToTransfer = {
        ...rest,
        schoolTypes: schoolTypes,
      };

      updateSchool(user, id, dataToTransfer)
        .then(res => {
          this.setState({
            isSchoolFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setItems();
        })
        .catch(error => {
          const errorTextInObject = propz.get(error, ['response', 'data', 'details', 'text', 'errmsg'], '');
          const errorText =
            typeof error.response.data.details.text === 'string' ? error.response.data.details.text : errorTextInObject;
          console.error(errorText);
          this.setState({
            isLoading: false,
            isSchoolFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    } else {
      addSchool(user, data)
        .then(res => {
          const lastPage = getPagesCount(total + 1, LIMIT);
          this.setState({
            isSchoolFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setCurrentPageParams(lastPage);
          this.setItems();
        })
        .catch(error => {
          const errorTextInObject = propz.get(error, ['response', 'data', 'details', 'text', 'errmsg'], '');
          const errorText =
            typeof error.response.data.details.text === 'string' ? error.response.data.details.text : errorTextInObject;
          console.error(errorText);
          this.setState({
            isLoading: false,
            isSchoolFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    }
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderFormModal(): React.ReactNode {
    const { isSchoolFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 1) {
      const school = selectedItems[0];
      return (
        <SimpleModal isOpen={isSchoolFormModalOpen}>
          <SchoolForm user={user} school={school} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isSchoolFormModalOpen}>
          <SchoolForm user={user} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
        </SimpleModal>
      );
    }
  }

  onSubmitCreateVoucherForm = data => {
    const { user, history } = this.props;
    const { discount, scope, code, description, startDate, endDate, isOneOffVoucher } = data;
    const { discountType, minOrderAmount, discountAmount, product, priceType } = discount;
    const { scopeType, schools, purchaseSource } = scope;

    const dataToTransfer = {
      code: code,
      description: description,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      isOneOffVoucher: isOneOffVoucher,
      discount: {
        minOrderAmount: Number(minOrderAmount),
        discountType: discountType,
      },
      scope: {
        scopeType: scopeType,
        purchaseSource: purchaseSource,
        schools: schools,
      },
    };

    const isDiscountTypeFreePostage = discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE;
    const isDiscountTypeProductDiscount = discountType === VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT;

    if (!isDiscountTypeFreePostage) {
      propz.set(dataToTransfer, ['discount', 'discountAmount'], Number(discountAmount));
      propz.set(dataToTransfer, ['discount', 'priceType'], priceType);
    }

    if (isDiscountTypeProductDiscount) {
      propz.set(dataToTransfer, ['discount', 'product'], product);
    }

    this.setState({
      isLoading: true,
    });

    addVoucher(user, dataToTransfer).then(voucher => {
      this.setState({
        isVoucherFormModalOpen: false,
      });

      history.push({
        pathname: '/vouchers',
      });
    });
  };

  renderCreateVoucherFormModal(): React.ReactNode {
    const { isVoucherFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isVoucherFormModalOpen}>
        <SchoolVoucherForm
          schools={selectedItems}
          user={user}
          onCancel={this.onCancelCreateVoucherButtonClick}
          onSubmit={this.onSubmitCreateVoucherForm}
        />
      </SimpleModal>
    );
  }

  onAssignSchoolTypeClick = (): void => {
    this.setState({
      isAssignSchoolTypeModalOpen: true,
    });
  };

  onDeleteSchoolTypeClick = (): void => {
    this.setState({
      isDeleteSchoolTypeModalOpen: true,
    });
  };

  getSchoolTypes(): SchoolSchoolType[] {
    const { selectedItems } = this.state;

    return Lazy(selectedItems)
      .map(selectedItem => selectedItem.schoolTypes || [])
      .filter(schoolTypes => schoolTypes.length > 0)
      .flatten()
      .uniq('typeId')
      .toArray();
  }

  onAssignSchoolTypeSubmitForm = (data: { id: string }) => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    const dataConverted = [data.id];

    this.setState({
      isLoading: true,
    });

    Promise.all(selectedItems.map(selectedItem => assignSchoolType(user, selectedItem.id, dataConverted))).then(
      items => {
        this.setState({
          isAssignSchoolTypeModalOpen: false,
          selectedItems: items,
        });
        this.setItems();
      }
    );
  };

  onDeleteSchoolTypeSubmitForm = (data: { id: string }) => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    const dataConverted = [data.id];

    this.setState({
      isLoading: true,
    });

    Promise.all(selectedItems.map(selectedItem => unassignSchoolType(user, selectedItem.id, dataConverted))).then(
      items => {
        this.setState({
          isDeleteSchoolTypeModalOpen: false,
          selectedItems: items,
        });
        this.setItems();
      }
    );
  };

  renderAssignSchoolTypeModal(): React.ReactNode {
    const { isAssignSchoolTypeModalOpen, schoolTypes } = this.state;

    return (
      <SimpleModal isOpen={isAssignSchoolTypeModalOpen}>
        <AssignSchoolTypeForm
          onCancel={this.onCancelAssignSchoolTypeModalButtonClick}
          onSubmit={this.onAssignSchoolTypeSubmitForm}
          schoolTypes={schoolTypes}
        />
      </SimpleModal>
    );
  }

  renderDeleteSchoolTypeModal(): React.ReactNode {
    const { isDeleteSchoolTypeModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isDeleteSchoolTypeModalOpen}>
        <DeleteSchoolTypeForm
          onCancel={this.onCancelDeleteSchoolTypeModalButtonClick}
          onSubmit={this.onDeleteSchoolTypeSubmitForm}
          schoolTypes={this.getSchoolTypes()}
        />
      </SimpleModal>
    );
  }

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${selectedItems.length === 1 ? 'school' : 'schools'}?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteSchoolClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  onCreateOfferClick = () => {
    this.setState({
      isOfferFormModalOpen: true,
    });
  };

  onCancelCreateOfferButtonClick = () => {
    this.setState({
      isOfferFormModalOpen: false,
    });
  };

  onCancelUploadStudentsButtonClick = () => {
    this.setState({
      isUploadStudentsFormModalOpen: false,
    });
  };

  onSubmitCreateOfferForm = data => {
    const { user, history } = this.props;
    const { productListPrice, ...rest } = data;

    const dataToTransfer = {
      ...rest,
      productOffers: data.productOffers.map(productOffer => ({
        ...productOffer,
        price: Number(productOffer.price),
      })),
    };

    this.setState({
      isLoading: true,
    });

    addOffer(user, dataToTransfer).then(offer => {
      this.setState({
        isOfferFormModalOpen: false,
      });

      history.push({
        pathname: '/offers',
      });
    });
  };

  onShowStatusUploadStudents = (isErrorStatus, data) => {
    this.setState({
      isLoading: true,
      isUploadStudentsFormModalOpen: false,
    });
    if (isErrorStatus) {
      const errorText = JSON.stringify(propz.get(data, ['response'], ''));
      console.error(errorText);
      this.setState({
        isLoading: false,
        isErrorModalOpen: true,
        errorMessage: errorText,
      });
    } else {
      const isErrorResultUpload = propz.get(data, ['isError'], '');
      console.log('isError');
      console.log(isErrorResultUpload);
      switch (true) {
        case typeof data !== 'undefined' && data === '':
          const textAsyncResponse =
            'CSV are being uploaded on the server. ' +
            'Refresh the page in the browser to see uploading status updates.';
          this.setState({
            isLoading: false,
            resultUploadText: textAsyncResponse,
            isGoodSendingModalOpen: true,
          });
          break;
        case typeof data.result !== 'undefined':
          const resultUploadText = data.result;
          this.setState({
            isLoading: false,
            resultUploadText: resultUploadText,
            isGoodSendingModalOpen: true,
          });
          break;
        default:
          this.setState({
            isLoading: false,
          });
      }
    }
  };

  onCloseGoodSendingModalClick = () => {
    this.setState({
      isGoodSendingModalOpen: false,
      currentTabIndex: 0,
      isLoading: true,
    });
    this.setItems();
  };

  renderGoodSendingModal(): React.ReactNode {
    const { isGoodSendingModalOpen, resultUploadText } = this.state;
    return (
      <SimpleModal
        isOpen={isGoodSendingModalOpen}
        title={''}
        body={resultUploadText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseGoodSendingModalClick}
      />
    );
  }

  onUploadStudentsClick = () => {
    this.setState({
      isUploadStudentsFormModalOpen: true,
    });
  };

  renderUploadStudentsFormModal(): React.ReactNode {
    const { isUploadStudentsFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isUploadStudentsFormModalOpen}>
        <SchoolUploadStudentsForm
          user={user}
          school={selectedItems[0]}
          onCancel={this.onCancelUploadStudentsButtonClick}
          onShowStatusUploadImages={this.onShowStatusUploadStudents}
        />
      </SimpleModal>
    );
  }

  renderCreateOfferFormModal(): React.ReactNode {
    const { isOfferFormModalOpen, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isOfferFormModalOpen}>
        <SchoolOfferForm
          schools={selectedItems}
          user={user}
          onCancel={this.onCancelCreateOfferButtonClick}
          onSubmit={this.onSubmitCreateOfferForm}
        />
      </SimpleModal>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      schoolTypes,
      autocompleteSearchFunctions,
      autocompleteGetElementsTitles,
      autocompleteDefaultTexts,
    } = this.state;

    const actionItems = [
      {
        itemText: 'Create school',
        onItemClick: this.onCreateSchoolClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: 'Edit school',
        onItemClick: this.onEditSchoolClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete school' : 'Delete schools',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Assign school type',
        onItemClick: this.onAssignSchoolTypeClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Delete school type',
        onItemClick: this.onDeleteSchoolTypeClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Create voucher',
        onItemClick: this.onCreateVoucherClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Create offer',
        onItemClick: this.onCreateOfferClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: 'Upload students',
        onItemClick: this.onUploadStudentsClick,
        isActive: selectedItems.length === 1,
      },
    ];

    const filterOptions = {
      schoolTypes: getSelectOptionForSchoolTypes(schoolTypes),
    };

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        autocompleteSearchFunctions={autocompleteSearchFunctions}
        autocompleteGetElementsTitles={autocompleteGetElementsTitles}
        onTableAutocompleteFilterChange={this.onTableAutocompleteFilterChange}
        autocompleteDefaultTexts={autocompleteDefaultTexts}
        rowClassName=""
      />
    );
  }
  getTabs(): React.ReactNode {
    const {
      currentTabIndex,
      selectedItems,
      schoolForms,
      schoolStudents,
      schoolUsers,
      schoolDetailsChangeLogs,
    } = this.state;
    const tabs = SCHOOLS_TABS;
    const currentTab = tabs[currentTabIndex];

    switch (currentTab) {
      case TABS.SUMMARY:
        return <SchoolSummary schoolItem={selectedItems[0]} />;
      case TABS.SCHOOL_FORMS:
        return <SchoolFormsSummary schoolForms={schoolForms} />;
      case TABS.PUPILS:
        return <SchoolStudentSummary schoolStudents={schoolStudents} />;
      case TABS.USERS:
        return <SchoolUserSummary schoolUsers={schoolUsers} />;
      case TABS.SCHOOL_DETAILS_CHANGE_LOGS:
        return <SchoolDetailsChangeLogSummary schoolDetailsChangeLogs={schoolDetailsChangeLogs} />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const schoolItem = selectedItems[0];
    const schoolId = schoolItem.id;

    console.log('School ID:', schoolId); // Check if this outputs a plain string

    const tabs = SCHOOLS_TABS;
    const currentTab = tabs[index];

    switch (currentTab) {
      case TABS.SCHOOL_FORMS:
        this.setState({ isLoading: true });
        getAllSchoolForms(user, schoolId).then(schoolForms => {
          this.setState({
            schoolForms,
            currentTabIndex: index,
            isLoading: false,
          });
        });
        break;
      case TABS.PUPILS:
        this.setState({ isLoading: true });
        getAllStudents(user, schoolId).then(students => {
          this.setState({
            schoolStudents: students,
            currentTabIndex: index,
            isLoading: false,
          });
        });
        break;
      case TABS.USERS:
        this.setState({ isLoading: true });
        getAllSchoolUsers(user, schoolId).then(schoolUsers => {
          this.setState({
            schoolUsers: schoolUsers,
            currentTabIndex: index,
            isLoading: false,
          });
        });
        break;
      case TABS.SCHOOL_DETAILS_CHANGE_LOGS:
        this.setState({ isLoading: true });
        getAllSchoolDetailsChangeLogs(user, schoolId).then(schoolDetailsChangeLogs => {
          this.setState({
            schoolDetailsChangeLogs: schoolDetailsChangeLogs,
            currentTabIndex: index,
            isLoading: false,
          });
        });
        break;
      default:
        this.setState({ currentTabIndex: index });
    }
  };

  onEditSchoolFormsButtonClick = () => {
    const { selectedItems } = this.state;
    const schoolItem = selectedItems[0];
    const { id } = schoolItem;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/forms`,
      search: `school=${id}`,
      state: { search: search },
    });
  };

  onEditSchoolStudentsButtonClick = () => {
    const { selectedItems } = this.state;
    const schoolItem = selectedItems[0];
    const { id } = schoolItem;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/students`,
      search: `school=${id}`,
      state: { search: search },
    });
  };

  onEditSchoolUsersButtonClick = () => {
    const { selectedItems } = this.state;
    const schoolItem = selectedItems[0];
    const { id } = schoolItem;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/schoolUsers`,
      search: `school=${id}`,
      state: { search: search },
    });
  };

  onEditSchoolDetailsChangeLogsButtonClick = () => {
    const { selectedItems } = this.state;
    const schoolItem = selectedItems[0];
    const { id } = schoolItem;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `${basePath}/changeLogs`,
      search: `school=${id}`,
      state: { search: search },
    });
  };

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a school above</div>;
    }

    const tabs = this.getTabs();
    const currentTab = SCHOOLS_TABS[currentTabIndex];

    const isSchoolFormsTab = currentTab === TABS.SCHOOL_FORMS;
    const isSchoolStudentsTab = currentTab === TABS.PUPILS;
    const isSchoolUsersTab = currentTab === TABS.USERS;
    const isSchoolDetailsChangeLogsTab = currentTab === TABS.SCHOOL_DETAILS_CHANGE_LOGS;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={SCHOOLS_TABS} currentTabIndex={currentTabIndex}>
        {isSchoolFormsTab && (
          <Button
            onClick={this.onEditSchoolFormsButtonClick}
            text={'Edit school forms'}
            customClass={'mt-3 mr-3 mb-3'}
          />
        )}
        {isSchoolStudentsTab && (
          <Button
            onClick={this.onEditSchoolStudentsButtonClick}
            text={'Edit school students'}
            customClass={'mt-3 mr-3 mb-3'}
          />
        )}
        {isSchoolUsersTab && (
          <Button
            onClick={this.onEditSchoolUsersButtonClick}
            text={'Edit school users'}
            customClass={'mt-3 mr-3 mb-3'}
          />
        )}
        {isSchoolDetailsChangeLogsTab && (
          <Button
            onClick={this.onEditSchoolDetailsChangeLogsButtonClick}
            text={'View full logs'}
            customClass={'mt-3 mr-3 mb-3'}
          />
        )}
        {tabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      isLoading,
      isSchoolFormModalOpen,
      isAssignSchoolTypeModalOpen,
      isDeleteSchoolTypeModalOpen,
      isVoucherFormModalOpen,
      isOfferFormModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isSchoolFormModalOpen ||
      isAssignSchoolTypeModalOpen ||
      isDeleteSchoolTypeModalOpen ||
      isVoucherFormModalOpen ||
      isOfferFormModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderFormModal()}
        {this.renderCreateVoucherFormModal()}
        {this.renderCreateOfferFormModal()}
        {this.renderAssignSchoolTypeModal()}
        {this.renderDeleteSchoolTypeModal()}
        {this.renderUploadStudentsFormModal()}
        {this.renderGoodSendingModal()}
        {this.renderConfirmationModal()}
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
