import * as React from 'react';
import * as propz from 'propz';
import { Component } from 'react';
import {
  ColumnDefinition,
  CustomCellColumnDefinition,
  FunctionAccessorColumnDefinition,
  isCustomCellColumnDefinition,
  isFunctionAccessorBasedColumnDefinition,
  isPathAccessorBasedColumnDefinition,
  PathAccessorColumnDefinition,
} from '../../helpers/table';
import { TextCell } from './Cells/TextCell';
import { AppUser } from '../../views/App/App';

interface Props {
  dataItems: any[];
  dataItemsSelected?: any[];
  columns: ColumnDefinition[];
  onDataItemClick: (index: number) => void;
  user?: AppUser;
  disableSelection?: boolean; // Prop for disabling selection
  rowClassName?: string; // New prop for custom row class name
}

export class GridBody extends Component<Props> {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    dataItemsSelected: [],
    disableSelection: false, // Default to false
    rowClassName: '', // Default to an empty string if not provided
  };

  render() {
    const { dataItems, columns, dataItemsSelected, onDataItemClick, disableSelection, rowClassName, user } = this.props;
    const options = { user };

    const renderDataRow = (dataItem: any, columns: ColumnDefinition[]) => {
      const rowCells = columns.map(column => {
        switch (true) {
          case isPathAccessorBasedColumnDefinition(column): {
            const pathAccessorBasedColumn = column as PathAccessorColumnDefinition;
            const value = propz.get<any>(dataItem, pathAccessorBasedColumn.accessor, '');
            const strValue = String(value);
            return <TextCell isShort={column.isShort} key={column.field} text={strValue} />;
          }

          case isFunctionAccessorBasedColumnDefinition(column): {
            const funcAccessorColumn = column as FunctionAccessorColumnDefinition;
            const value = funcAccessorColumn.accessor(dataItem, options);
            const strValue = String(value);
            return <TextCell isShort={column.isShort} key={column.field} text={strValue} />;
          }

          case isCustomCellColumnDefinition(column): {
            const cellColumn = column as CustomCellColumnDefinition;
            const cell = cellColumn.cell(dataItem);
            return cell;
          }

          default: {
            console.error('Not supported column type: ' + JSON.stringify(column, null, 2));
            return null;
          }
        }
      });

      return rowCells;
    };

    return (
      <tbody>
        {dataItems.map((dataItem, index) => {
          const isDataItemSelected = dataItemsSelected.some(dataItemSelected => dataItemSelected.id === dataItem.id);

          const rowClasses = [
            isDataItemSelected
              ? rowClassName && rowClassName !== ''
                ? 'custom-selected-row'
                : 'table-primary'
              : rowClassName && rowClassName !== ''
              ? rowClassName
              : '', // Apply 'custom-selected-row' if custom class is set, otherwise use 'table-primary', and apply no class if unselected and no custom class
          ]
            .filter(Boolean)
            .join(' ');

          return (
            <tr key={dataItem.id} className={rowClasses}>
              {!disableSelection ? (
                <td>
                  <input
                    onChange={() => {
                      onDataItemClick(index);
                    }}
                    className=""
                    type="checkbox"
                    checked={isDataItemSelected}
                  />
                </td>
              ) : (
                <td style={{ width: '1px' }}></td>
              )}
              {renderDataRow(dataItem, columns)}
            </tr>
          );
        })}
      </tbody>
    );
  }
}
