import * as React from 'react';
import { Component } from 'react';
import { AppUser } from '../../../../../App';
import { History, Location } from 'history';
import {
  ColumnDefinition,
  convertToFilterWithPlus,
  getFilters,
  getOrder,
  getPagesCount,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from '../../../../../../../helpers/table';
import { School } from '../../../../../../../models/schools';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from '../../../../../../../consts/table';
import { parse } from 'query-string';
import { getSchool } from '../../../../../../../services/superadmin/schools';
import * as Promise from 'bluebird';
import {
  getSchoolDetailsChangeLogs,
  getSchoolDetailsChangeLogsCount,
  getAllSchoolDetailsChangeLogs,
} from '../../../../../../../services/superadmin/schoolDetailsChangeLog';
import { SimpleModal } from '../../../../../../../components/SimpleModal/SimpleModal';
import { Loader } from '../../../../../../../components/Loader/Loader';
import { Grid } from '../../../../../../../components/Grid/Grid';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Field',
    field: 'fieldName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: item => item.fieldName,
  },
  {
    text: 'Old Value',
    field: 'originalValue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: item => item.originalValue || '-',
  },
  {
    text: 'New Value',
    field: 'newValue',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: item => item.newValue || '-',
  },
  {
    text: 'Changed By',
    field: 'userName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: item => `${item.userName} ${item.userSurname} (${item.userEmail})`,
  },
  {
    text: 'Timestamp',
    field: 'timestamp',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: item => new Date(item.timestamp).toLocaleString(),
  },
];

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  items: any[];
  currentPage: number;
  selectedItems: any[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  schoolItem: School;
  isErrorModalOpen: boolean;
  errorMessage: string;
}

export class SchoolDetailsChangeLogs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: 'DESC',
      sortColumnsName: 'timestamp',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {
        userName: '',
        userSurname: '',
        userEmail: '',
        timestamp: {
          from: '',
          to: '',
        },
      },
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      schoolItem: undefined,
      isErrorModalOpen: false,
      errorMessage: '',
    };
  }

  getSchoolId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    return search.school as string;
  }

  convertToFilterWithPlus(filters: any): any {
    const convertedFilters = { ...filters };
    if (convertedFilters.timestamp) {
      if (convertedFilters.timestamp.from) {
        convertedFilters.timestamp.from = new Date(convertedFilters.timestamp.from).toISOString();
      }
      if (convertedFilters.timestamp.to) {
        convertedFilters.timestamp.to = new Date(convertedFilters.timestamp.to).toISOString();
      }
    }

    return convertedFilters;
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      sortDirection: 'DESC',
      sortColumnsName: 'timestamp',
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;
    const schoolId = this.getSchoolId();

    return getSchool(user, schoolId).then(schoolItem => {
      this.setState({
        schoolItem: schoolItem,
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;
    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    const filters = getFilters(COLUMNS, search);
    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const { sortDirection, sortColumnsName } = this.state;
    const where = getServerFieldSectionWhere(filters);
    const schoolId = this.getSchoolId();

    // Adjust the call to match the expected parameters (without passing `order`)
    const getAllItemsPromise = getAllSchoolDetailsChangeLogs(user, schoolId, where);
    const getItemsCountPromise = getSchoolDetailsChangeLogsCount(user, schoolId, where);

    const promises = [getItemsCountPromise, getAllItemsPromise];

    return Promise.all(promises)
      .then(([countObj, logs]) => {
        const flattenedItems = logs.flatMap(log =>
          log.changedFields.map(field => ({
            ...field,
            userName: log.userName || '',
            userSurname: log.userSurname || '',
            userEmail: log.userEmail || '',
            timestamp: log.timestamp,
          }))
        );

        // Apply sorting here if needed
        const sortedItems = flattenedItems.sort((a, b) => {
          if (sortDirection === 'DESC') {
            return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
          }
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        });

        const totalFieldChanges = sortedItems.length;
        const limit = LIMIT;
        const offset = (page - 1) * limit;

        const paginatedItems = sortedItems.slice(offset, offset + limit);

        this.setState({
          currentPage: page,
          isShowFilter: isShowFilter,
          isDataFiltered: isDataFiltered,
          filters: filters,
          items: paginatedItems,
          total: totalFieldChanges,
          basePath: history.location.pathname,
          isLoading: false,
        });

        return true;
      })
      .catch(error => {
        this.setState({
          isErrorModalOpen: true,
          errorMessage: 'Failed to fetch data.',
          isLoading: false,
        });
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const schoolId = this.getSchoolId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `school=${schoolId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const schoolId = this.getSchoolId();

    getSchoolDetailsChangeLogs(user, schoolId, where)
      .then(items => {
        const itemsFiltered = items.filter(item => {
          return selectedItems.every(selectedItem => selectedItem.id !== item.id);
        });

        const selectedItemsNext = [...selectedItems, ...itemsFiltered];

        this.setState({
          selectedItems: selectedItemsNext,
          isSelectAllChecked: true,
          isLoading: false,
        });
      })
      .catch(error => {
        console.error('Error selecting all items:', error);
        this.setState({
          isErrorModalOpen: true,
          errorMessage: 'Failed to select all items.',
          isLoading: false,
        });
      });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const schoolId = this.getSchoolId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `school=${schoolId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;

    let nextFilters = { ...filters };

    if (filterField === 'userName') {
      nextFilters[filterField] = filterValue;
    } else if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...filters[filterField],
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...filters[filterField],
              to: filterValue,
            },
          };
          break;
      }
    } else {
      nextFilters[filterField] = filterValue;
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const schoolId = this.getSchoolId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `school=${schoolId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      filters: {
        userName: '',
        userSurname: '',
        userEmail: '',
        timestamp: { from: '', to: '' },
      },
      selectedItems: [],
      isSelectAllChecked: false,
      sortDirection: 'DESC',
      sortColumnsName: 'timestamp',
    });

    const schoolId = this.getSchoolId();
    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `school=${schoolId}`,
      state: history.location.state,
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const schoolId = this.getSchoolId();

    history.push({
      pathname: '/schools',
      search: search,
      state: { schoolId: schoolId },
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
      isLoading: true,
    });
    this.setItems();
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      schoolItem,
      isErrorModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const gridTitle = typeof schoolItem !== 'undefined' ? `${schoolItem.name} / Details Change Log` : '';

    const classes = isErrorModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderSendingError()}
        <div className="row">
          <div className="col-md-12">
            <Grid
              className="school-details-change-logs-grid"
              dataItems={items}
              filters={filters}
              currentPage={currentPage}
              total={total}
              isSelectAllChecked={isSelectAllChecked}
              isDataFiltered={isDataFiltered}
              sortDirection={sortDirection}
              sortColumnsName={sortColumnsName}
              isShowFilter={isShowFilter}
              dataItemsSelected={[]}
              columns={COLUMNS}
              onItemClick={this.onItemClick}
              onSortClick={this.onTableSortClick}
              onApplyFilterClick={this.onApplyFilterClick}
              onClearFilterClick={this.onClearFilterClick}
              onTableFilterChange={this.onTableFilterChange}
              onTableFilterClick={this.onTableFilterClick}
              setCurrentPageParams={this.setCurrentPageParams}
              onSelectAllClick={this.onSelectAllClick}
              onSelectAllOnPageClick={this.onSelectAllOnPageClick}
              onUnselectAllClick={this.onUnselectAllClick}
              gridTitle={gridTitle}
              actionItems={[]}
              goBack={this.goBack}
              useCustomPagination={true}
              disableSelection={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
