import * as React from 'react';
import { Component } from 'react';
import { School } from 'Src/models/schools';
import { getSchoolUserSchool, updateSchoolUserSchool } from '../../../services/schoolUser/schools';
import { AppSchoolsUser2 } from '../AppSchools2';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'Src/components/Button/Button';
import AutoResizingTextarea from 'Src/components/AutoResizingTextarea/AutoResizingTextarea';
import '../appSchools2.css';

interface Props {
  user: AppSchoolsUser2;
}

interface State {
  school: School | null;
  loading: boolean;
  error: string | null;
  editing: boolean;
  successMessage: string | null;
}

export class SchoolAppSchoolInformation2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      school: null,
      loading: true,
      error: null,
      editing: false,
      successMessage: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    getSchoolUserSchool(user)
      .then(school => {
        this.setState({ school, loading: false });
      })
      .catch(error => {
        this.setState({ error: error.message, loading: false });
      });
  }

  handleEdit = () => {
    this.setState({ editing: true });
  };

  handleCancel = () => {
    this.setState({ editing: false });
  };

  handleSubmit = values => {
    const { user } = this.props;
    const { school } = this.state;

    if (!school) {
      console.error('No school information available for updating.');
      return;
    }

    const dataToUpdate = {
      ...values,
      id: school.id,
    };

    this.setState({ loading: true });
    updateSchoolUserSchool(user, dataToUpdate)
      .then(updatedSchool => {
        this.setState({
          school: updatedSchool,
          loading: false,
          editing: false,
          successMessage: 'Changes have been saved successfully!',
        });

        setTimeout(() => {
          this.setState({ successMessage: null });
        }, 3000);
      })
      .catch(error => {
        this.setState({ error: error.message, loading: false });
      });
  };

  render() {
    const { school, loading, error, editing, successMessage } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    if (!school) {
      return <div>No school information available.</div>;
    }

    const SchoolSchema = Yup.object().shape({
      name: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      code: Yup.string().required('Required'),
      email: Yup.string()
        .email('Must be a valid email')
        .nullable(),
      phone: Yup.string().nullable(),
      schoolAddress: Yup.string().nullable(),
      siteLocation: Yup.string().nullable(),
      leaNumber: Yup.number().required('Required'),
      dfesNumber: Yup.number().required('Required'),
      databaseType: Yup.string().nullable(),
      bankDetails: Yup.object().shape({
        accountName: Yup.string().nullable(),
        sortCode: Yup.string().nullable(),
        accountNumber: Yup.string().nullable(),
      }),
    });

    return (
      <div className="schoolAppContentWrapper">
        {successMessage && <div className="success-message">{successMessage}</div>}
        <Formik
          initialValues={{
            name: school.name,
            city: school.city,
            code: school.code,
            email: school.email || '',
            phone: school.phone || '',
            schoolAddress: school.schoolAddress || '',
            siteLocation: school.siteLocation || '',
            leaNumber: school.leaNumber || '',
            dfesNumber: school.dfesNumber || '',
            databaseType: school.databaseType || '',
            bankDetails: {
              accountName: school.bankDetails?.accountName || '',
              sortCode: school.bankDetails?.sortCode || '',
              accountNumber: school.bankDetails?.accountNumber || '',
            },
          }}
          validationSchema={SchoolSchema}
          onSubmit={this.handleSubmit}
        >
          {({ handleSubmit }) => (
            <Form>
              <div className="page-header">
                <img
                  src="/dist/images/icon/establishmentInformation.png"
                  alt="Establishment Information"
                  className="header-icon"
                />
                <h1>Establishment Information</h1>
                {editing ? (
                  <div className="custom-button-container">
                    <button type="submit" className="custom-button">
                      <img src="/dist/images/icon/save.png" alt="Save" className="custom-edit-icon" />
                      Save
                    </button>
                    <button type="button" onClick={this.handleCancel} className="custom-button">
                      <img src="/dist/images/icon/cancel.png" alt="Cancel" className="custom-edit-icon" />
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button type="button" onClick={this.handleEdit} className="custom-button">
                    <img src="/dist/images/icon/edit.png" alt="Edit" className="custom-edit-icon" />
                    Edit
                  </button>
                )}
              </div>
              <div className="school-details">
                {[
                  { label: 'School Name', value: school.name, field: 'name' },
                  { label: 'School Town', value: school.city, field: 'city' },
                  { label: 'School Email', value: school.email, field: 'email' },
                  { label: 'School Phone', value: school.phone, field: 'phone' },
                  { label: 'School Address', value: school.schoolAddress, field: 'schoolAddress' },
                  { label: 'Site Location', value: school.siteLocation, field: 'siteLocation' },
                  { label: 'LEA Number', value: school.leaNumber, field: 'leaNumber' },
                  { label: 'DFES Number', value: school.dfesNumber, field: 'dfesNumber' },
                  { label: 'Database Type', value: school.databaseType, field: 'databaseType' },
                  {
                    label: 'Bank Account Name',
                    value: school.bankDetails?.accountName,
                    field: 'bankDetails.accountName',
                  },
                  { label: 'Sort Code', value: school.bankDetails?.sortCode, field: 'bankDetails.sortCode' },
                  {
                    label: 'Account Number',
                    value: school.bankDetails?.accountNumber,
                    field: 'bankDetails.accountNumber',
                  },
                ].map(({ label, value, field }) => (
                  <div key={field} className="detail-row">
                    <label className="field-name">{label}</label>
                    <div className="field-value">
                      {editing ? (
                        <>
                          <Field
                            name={field}
                            as={
                              field.includes('schoolAddress') || field.includes('siteLocation')
                                ? AutoResizingTextarea
                                : 'input'
                            }
                            className="form-control mb-3"
                          />
                          <ErrorMessage component="div" className="alert alert-danger" name={field} />
                        </>
                      ) : (
                        <div>{value}</div>
                      )}
                    </div>
                    <hr className="field-divider" />
                  </div>
                ))}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
