import * as React from 'react';
import { FunctionComponent } from 'react';
import { Pagination } from './Pagination/Pagination';
import { SelectAllCheckbox } from './SelectAllCheckbox/SelectAllCheckbox';
import { FilterIcon } from './FilterIcon/FilterIcon';
import { Button } from '../Button/Button';
import { PdfIcon } from './PdfIcon/PdfIcon';
import { CsvIcon } from './CsvIcon/CsvIcon';
import { ColumnDefinition } from 'Src/helpers/table';
import { ActionsButton, ActionItem } from './ActionsButton/ActionsButton';
import { SumField } from './SumField/SumField';
import { AppUser } from '../../views/App/App';

interface Props {
  user: AppUser;
  actionItems: ActionItem[];
  currentPage: number;
  setCurrentPageParams: (currentPage: number) => void;
  total: number;
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  isSelectAllChecked: boolean;
  selectedItems: any[];
  onTableFilterClick: (event: any) => void;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  columns: ColumnDefinition[];
  filters: any;
  goBack?: () => void;
  gridTitle?: string;
  isPDF?: boolean;
  isCSV?: boolean;
  isSelectAllDisabled?: boolean;
  sumFields?: { field: string; text: string }[];
  useCustomPagination?: boolean; // Add this property to the interface
  disableSelection?: boolean;
  disableCsvButton?: boolean; // New prop
}

export const GridPanel: FunctionComponent<Props> = ({
  actionItems,
  currentPage,
  setCurrentPageParams,
  total,
  onSelectAllClick,
  onSelectAllOnPageClick,
  onUnselectAllClick,
  isSelectAllChecked,
  selectedItems,
  onTableFilterClick,
  isShowFilter,
  isDataFiltered,
  goBack,
  gridTitle,
  isPDF,
  isCSV,
  columns,
  filters,
  isSelectAllDisabled,
  sumFields,
  user,
  useCustomPagination,
  disableSelection,
  disableCsvButton,
}) => {
  const isShowFilters = Object.keys(filters).length > 0;
  const isShowSumFields = Array.isArray(sumFields) && sumFields.length > 0;

  return (
    <>
      {gridTitle && goBack && (
        <div className="row">
          <div className="col-md-12">
            <Button text={'← back'} onClick={goBack} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="eGridTitle h3 mb-3">{gridTitle}</div>
          </div>
        </div>
      )}

      {disableSelection ? (
        <div className="row">
          <div className="col-md-12 d-flex align-items-center justify-content-between">
            {isShowFilters && (
              <FilterIcon
                onTableFilterClick={onTableFilterClick}
                isFilterShown={isShowFilter}
                isDataFiltered={isDataFiltered}
              />
            )}
            <Pagination
              currentPage={currentPage}
              setCurrentPageParams={setCurrentPageParams}
              total={total}
              useCustomPagination={useCustomPagination}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">{actionItems.length > 0 && <ActionsButton actionItems={actionItems} />}</div>
            <div className="col-md-6">
              <Pagination
                currentPage={currentPage}
                setCurrentPageParams={setCurrentPageParams}
                total={total}
                useCustomPagination={useCustomPagination}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <SelectAllCheckbox
                onSelectAllClick={onSelectAllClick}
                onSelectAllOnPageClick={onSelectAllOnPageClick}
                onUnselectAllClick={onUnselectAllClick}
                isChecked={isSelectAllChecked}
                totalCount={total}
                currentPage={currentPage}
                selectedItems={selectedItems}
                isSelectAllDisabled={isSelectAllDisabled}
                user={user}
              />
              {isShowSumFields &&
                sumFields.map((sumField, index) => (
                  <SumField
                    key={`grid_panel_sum_field_${index}`}
                    selectedItems={selectedItems}
                    field={sumField.field}
                    text={sumField.text}
                  />
                ))}
              {isShowFilters && (
                <FilterIcon
                  onTableFilterClick={onTableFilterClick}
                  isFilterShown={isShowFilter}
                  isDataFiltered={isDataFiltered}
                />
              )}
              {isPDF && <PdfIcon selectedItems={selectedItems} />}
              {isCSV && !disableCsvButton && <CsvIcon selectedItems={selectedItems} columns={columns} />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

GridPanel.displayName = 'GridPanel';
