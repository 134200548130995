import * as React from 'react';
import { School } from 'Src/models/schools';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { checkValidParentCode } from '../../../../../../../services/public/register';
import { checkParentCode } from '../../../../../../../services/superadmin/schools';
import { AppUser } from '../../../../../App';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  school?: School;
  user: AppUser;
}

import { useFormikContext } from 'formik';

export function SchoolForm(props: Props) {
  const { school, onSubmit, onCancel, user } = props;
  const SchoolSchema = React.useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      code: Yup.string().required('Required'),
      email: Yup.string().email('Must be valid email'),
      phone: Yup.string(),
      schoolAddress: Yup.string(),
      siteLocation: Yup.string(),
      leaNumber: Yup.number().required('Required'),
      dfesNumber: Yup.number().required('Required'),
      databaseType: Yup.string(),
      parentCode: Yup.string().test('parentCode', 'Duplicate error', async (value, testContext) => {
        if (!value) {
          return true;
        }
        const schoolId = school?.id;
        const res = await checkParentCode(user, value, schoolId);
        return res.isAvailable;
      }),
      bankDetails: Yup.object().shape({
        accountName: Yup.string(),
        sortCode: Yup.string(),
        accountNumber: Yup.string(),
      }),
    });
  }, [school?.id, user]);

  const isSchoolExist = typeof school !== 'undefined';
  const schoolForm = {
    name: isSchoolExist ? school.name : '',
    city: isSchoolExist ? school.city : '',
    code: isSchoolExist ? school.code : '',
    email: isSchoolExist ? school.email : '',
    phone: isSchoolExist ? school.phone : '',
    schoolAddress: isSchoolExist ? school.schoolAddress : '',
    siteLocation: isSchoolExist ? school.siteLocation : '',
    leaNumber: isSchoolExist ? school.leaNumber : undefined,
    dfesNumber: isSchoolExist ? school.dfesNumber : undefined,
    databaseType: isSchoolExist ? school.databaseType : '',
    parentCode: isSchoolExist ? school.parentCode : '',
    bankDetails: {
      accountName: isSchoolExist ? school.bankDetails?.accountName : '',
      sortCode: isSchoolExist ? school.bankDetails?.sortCode : '',
      accountNumber: isSchoolExist ? school.bankDetails?.accountNumber : '',
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={schoolForm}
            validationSchema={SchoolSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>School Reference</label>
                    <Field name="code" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="code" />
                  </div>

                  <div className="form-group">
                    <label>School name</label>
                    <Field name="name" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>

                  <div className="form-group">
                    <label>School town</label>
                    <Field name="city" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="city" />
                  </div>

                  <div className="form-group">
                    <label>School email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  <div className="form-group">
                    <label>School phone</label>
                    <Field name="phone" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="phone" />
                  </div>

                  <div className="form-group">
                    <label>School address</label>
                    <Field name="schoolAddress" as="textarea" className="form-control mb-3" rows={3} />
                    <ErrorMessage component="div" className="alert alert-danger" name="schoolAddress" />
                  </div>

                  <div className="form-group">
                    <label>Site Location</label>
                    <Field name="siteLocation" as="textarea" className="form-control mb-3" rows={3} />
                    <ErrorMessage component="div" className="alert alert-danger" name="siteLocation" />
                  </div>

                  <div className="form-group">
                    <label>LEA Number</label>
                    <Field name="leaNumber" type="number" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="leaNumber" />
                  </div>

                  <div className="form-group">
                    <label>DFES Number</label>
                    <Field name="dfesNumber" type="number" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="dfesNumber" />
                  </div>

                  <div className="form-group">
                    <label>Database Type</label>
                    <Field name="databaseType" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="databaseType" />
                  </div>

                  <div className="form-group">
                    <label>Parent code</label>
                    <Field name="parentCode" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="parentCode" />
                  </div>

                  <div className="form-group">
                    <label>Bank Account Name</label>
                    <Field name="bankDetails.accountName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="bankDetails.accountName" />
                  </div>

                  <div className="form-group">
                    <label>Sort Code</label>
                    <Field name="bankDetails.sortCode" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="bankDetails.sortCode" />
                  </div>

                  <div className="form-group">
                    <label>Account Number</label>
                    <Field name="bankDetails.accountNumber" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="bankDetails.accountNumber" />
                  </div>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
