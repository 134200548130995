import * as React from 'react';
import { FunctionComponent } from 'react';

interface Props {
  schoolDetailsChangeLogs: any[];
}

export const SchoolDetailsChangeLogSummary: FunctionComponent<Props> = props => {
  const { schoolDetailsChangeLogs } = props;

  // Flatten the logs to get a list of all changes with the associated timestamp and user details
  const allChanges = schoolDetailsChangeLogs.flatMap(log =>
    log.changedFields.map(field => ({
      ...field,
      timestamp: log.timestamp,
      userName: log.userName,
      userSurname: log.userSurname,
      userEmail: log.userEmail,
    }))
  );

  // Sort all changes by timestamp and take the 5 most recent
  const recentChanges = allChanges
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .slice(0, 5);

  // Create table rows with sequential order numbers
  const rows = recentChanges.map((change, index) => (
    <tr key={`school_details_log_summary_${index}`}>
      <td>{index + 1}</td>
      <td>{change.fieldName}</td>
      <td>{change.originalValue || '-'}</td>
      <td>{change.newValue || '-'}</td>
      <td>{`${change.userName} ${change.userSurname}`}</td>
      <td>{change.userEmail}</td>
      <td>{new Date(change.timestamp).toLocaleString()}</td>
    </tr>
  ));

  // Display an empty row if there are no recent changes
  const emptyRow = (
    <tr key={'school_details_log_empty_row'}>
      <td colSpan={7}>No recent changes</td>
    </tr>
  );

  return (
    <div>
      <table className={'table table-sm mPre'}>
        <thead>
          <tr>
            <th>#</th>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Changed By</th>
            <th>User Email</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
      </table>
    </div>
  );
};

SchoolDetailsChangeLogSummary.displayName = 'SchoolDetailsChangeLogSummary';
