import * as React from 'react';
import { Component } from 'react';
import { History, Location } from 'history';
import { parse } from 'query-string';
import * as Promise from 'bluebird';
import * as propz from 'propz';
import {
  ColumnDefinition,
  convertToFilterWithPlus,
  getFilters,
  getOrder,
  getPagesCount,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from '../../../helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, LIMIT } from '../../../consts/table';
import { AppSchoolsUser2 } from '../AppSchools2';
import {
  addSchoolUserSchoolUsers,
  deleteSchoolUserSchoolUser,
  getAllSchoolUserSchoolUsers,
  getSchoolUserSchoolUsers,
  getSchoolUserSchoolUsersCount,
  updateSchoolUserSchoolUser,
} from '../../../services/schoolUser/schools';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { SchoolAppSchoolUsersForm2 } from './SchoolAppSchoolUsersForm2/SchoolAppSchoolUsersForm2';
import { Loader } from '../../../components/Loader/Loader';
import { Grid } from '../../../components/Grid/Grid';
import { getIsDataNotificationEnabled, getIsSchoolAdmin } from '../../../helpers/accessor';
import { getSelectOptionForYesOrNo } from '../../../helpers/select';

interface Props {
  user: AppSchoolsUser2;
  history: History;
  location: Location;
}

interface State {
  items: any[];
  currentPage: number;
  selectedItems: any[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  isSchoolUserFormModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
}

export class SchoolAppSchoolUsers2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      isSchoolUserFormModalOpen: false,
      isConfirmationModalOpen: false,
      isErrorModalOpen: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.setItems();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.state.items.forEach((item, index) => {
      const phone = item.mobilePhone || '';

      const maskedPhone = phone.replace(/.(?=.{4})/g, '*');
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;
    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const filters = getFilters(this.getColumns(), search);
    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);
    const where = getServerFieldSectionWhere(filters);
    const order = sortColumnsName && sortDirection ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const getItemsPromise = getSchoolUserSchoolUsers(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolUserSchoolUsersCount(user, where);

    return Promise.all([getItemsCountPromise, getItemsPromise]).then(([countObj, items]) => {
      const maskedItems = items.map(item => {
        const phone = item.mobilePhone || '';
        const maskedPhone = phone.replace(/.(?=.{4})/g, '*');
        return { ...item, mobilePhone: maskedPhone };
      });

      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: maskedItems,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `${search.join('&')}`,
      state: history.location.state,
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolUserSchoolUsers(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `${search.join('&')}`,
      state: history.location.state,
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = this.getColumns().find(col => col.field === filterField);
      const filterType = filter?.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `${search.join('&')}`,
      state: history.location.state,
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: ``,
      state: history.location.state,
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isSchoolUserFormModalOpen: false,
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
      isLoading: true,
    });
    this.setItems();
  };

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    const { id } = activeSchool;

    this.setState({
      isLoading: true,
    });

    const dataToTransfer = {
      ...data,
      schoolId: id,
    };

    if (selectedItems.length === 1) {
      const schoolUser = selectedItems[0];
      const { id } = schoolUser;

      updateSchoolUserSchoolUser(user, id, dataToTransfer)
        .then(res => {
          this.setState({
            isSchoolUserFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(errorText);
          this.setState({
            isLoading: false,
            isSchoolUserFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    } else {
      addSchoolUserSchoolUsers(user, dataToTransfer)
        .then(res => {
          const lastPage = getPagesCount(total + 1, LIMIT);
          this.setState({
            isSchoolUserFormModalOpen: false,
            selectedItems: [res],
            isSelectAllChecked: false,
          });
          this.setCurrentPageParams(lastPage);
          this.setItems();
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(errorText);
          this.setState({
            isLoading: false,
            isSchoolUserFormModalOpen: false,
            isErrorModalOpen: true,
            errorMessage: errorText,
          });
        });
    }
  };

  renderFormModal(): React.ReactNode {
    const { isSchoolUserFormModalOpen, selectedItems } = this.state;

    const customModalClass = 'custom-user-modal';

    if (selectedItems.length === 1) {
      const schoolUser = selectedItems[0];
      return (
        <SimpleModal isOpen={isSchoolUserFormModalOpen} customClass={customModalClass}>
          <SchoolAppSchoolUsersForm2
            schoolUser={schoolUser}
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
            user={this.props.user}
          />
        </SimpleModal>
      );
    } else {
      return (
        <SimpleModal isOpen={isSchoolUserFormModalOpen} customClass={customModalClass}>
          <SchoolAppSchoolUsersForm2
            onCancel={this.onCancelButtonClick}
            onSubmit={this.onSubmitForm}
            user={this.props.user}
          />
        </SimpleModal>
      );
    }
  }

  onCreateSchoolUserClick = () => {
    this.setState({
      isSchoolUserFormModalOpen: true,
      selectedItems: [],
    });
  };

  onEditSchoolUserClick = (item: any): void => {
    this.setState({
      selectedItems: [item],
      isSchoolUserFormModalOpen: true,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    if (!isConfirmationModalOpen) {
      return null;
    }

    const schoolUser = selectedItems[0];
    const { user } = this.props;

    if (schoolUser && schoolUser.id === user.userId) {
      return (
        <SimpleModal isOpen={isConfirmationModalOpen} customClass="custom-user-modal">
          <div className="custom-modal-header">
            <h5>Error</h5>
            <button className="close" onClick={this.hideConfirmationModal}>
              &times;
            </button>
          </div>

          <div className="custom-modal-body">
            {'You cannot delete your own account. Please contact another admin to remove your account if necessary.'}
          </div>

          <div className="custom-button-container" style={{ marginTop: '20px' }}>
            <button onClick={this.hideConfirmationModal} className="custom-button">
              <img src="/dist/images/icon/cancel.png" alt="Cancel" className="custom-edit-icon" />
              Close
            </button>
          </div>
        </SimpleModal>
      );
    }

    return (
      <SimpleModal isOpen={isConfirmationModalOpen} customClass="custom-user-modal">
        <div className="custom-modal-header">
          <h5>Confirmation</h5>
          <button className="close" onClick={this.hideConfirmationModal}>
            &times;
          </button>
        </div>

        <div className="custom-modal-body">
          {`Are you sure you want to delete the user ${schoolUser.firstName} ${schoolUser.lastName}?`}
        </div>

        <div className="custom-button-container" style={{ marginTop: '20px' }}>
          <button onClick={this.hideConfirmationModal} className="custom-button">
            <img src="/dist/images/icon/cancel.png" alt="Cancel" className="custom-edit-icon" />
            Cancel
          </button>
          <button onClick={this.handleConfirmDelete} className="custom-button">
            <img src="/dist/images/icon/delete.png" alt="Delete" className="custom-edit-icon" />
            Delete
          </button>
        </div>
      </SimpleModal>
    );
  }

  handleConfirmDelete = (): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    if (selectedItems.length === 0) {
      return;
    }

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    Promise.all(selectedItems.map(selectedItem => deleteSchoolUserSchoolUser(user, selectedItem.id))).then(() => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });
      this.setItems();
    });
  };

  onDeleteSchoolUserClick = (item?: any): void => {
    const { user } = this.props;
    const userId = user.userId;
    let { selectedItems } = this.state;

    if (item) {
      selectedItems = [item];
      this.setState({ selectedItems }, () => {
        if (selectedItems.length === 1 && selectedItems[0].id === userId) {
          this.setState({ isConfirmationModalOpen: true });
          return;
        }
        this.showConfirmationModal();
      });
      return;
    }

    if (selectedItems.length === 1 && selectedItems[0].id === userId) {
      this.setState({ selectedItems: [], isConfirmationModalOpen: true });
      return;
    }

    if (selectedItems.length === 1 && selectedItems[0].id !== userId) {
      console.log('Opening confirmation modal for item:', selectedItems[0].id);
      this.showConfirmationModal();
      return;
    }
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  getColumns(): ColumnDefinition[] {
    return [
      {
        text: 'First name',
        field: 'firstName',
        isSort: true,
        type: FILTER_TYPE.TEXT,
        accessor: ['firstName'],
      },
      {
        text: 'Last name',
        field: 'lastName',
        isSort: true,
        type: FILTER_TYPE.TEXT,
        accessor: ['lastName'],
      },
      {
        text: 'Email',
        field: 'email',
        isSort: true,
        type: FILTER_TYPE.TEXT,
        accessor: ['email'],
      },
      {
        text: 'Phone',
        field: 'mobilePhone',
        isSort: true,
        type: FILTER_TYPE.TEXT,
        accessor: ['mobilePhone'],
      },
      {
        text: 'Notifications Enabled',
        field: 'isDataNotificationEnabled',
        isSort: false,
        type: FILTER_TYPE.SELECT,
        accessor: getIsDataNotificationEnabled,
      },
      {
        text: 'Admin Status',
        field: 'isSchoolAdmin',
        isSort: false,
        type: FILTER_TYPE.SELECT,
        accessor: getIsSchoolAdmin,
      },
      {
        text: 'Actions',
        field: 'actions',
        isSort: false,
        cell: (item: any): JSX.Element => {
          return (
            <td className="custom-action-cell">
              <button onClick={() => this.onEditSchoolUserClick(item)} className="custom-action-button">
                <img src="/dist/images/icon/edit.png" alt="Edit" className="custom-edit-icon" />
                Edit
              </button>
              <button onClick={() => this.onDeleteSchoolUserClick(item)} className="custom-action-button">
                <img src="/dist/images/icon/delete.png" alt="Delete" className="custom-edit-icon" />
                Delete
              </button>
            </td>
          );
        },
      },
    ];
  }

  render() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isLoading,
      isSelectAllChecked,
      currentPage,
      total,
      isSchoolUserFormModalOpen,
      isConfirmationModalOpen,
      isErrorModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isSchoolUserFormModalOpen || isConfirmationModalOpen || isErrorModalOpen ? 'mt-3' : 'mt-3';

    const { user } = this.props;
    const { activeSchool } = user;

    const gridTitle = `${activeSchool.name} / Users`;

    const filterOptions = {
      isDataNotificationEnabled: getSelectOptionForYesOrNo(),
      isSchoolAdmin: getSelectOptionForYesOrNo(),
    };

    return (
      <div className={classes}>
        {this.renderSendingError()}
        {this.renderFormModal()}
        {this.renderConfirmationModal()}
        <div className="schoolAppContentWrapper">
          <div className="page-header">
            <img src="/dist/images/icon/users.png" alt="Users" className="header-icon" />
            <h1>Users</h1>
            <button type="button" onClick={this.onCreateSchoolUserClick} className="custom-button">
              <img src="/dist/images/icon/plus.png" alt="Add User" className="custom-edit-icon" />
              Add User
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Grid
                dataItems={items}
                filters={filters}
                currentPage={currentPage}
                total={total}
                isSelectAllChecked={isSelectAllChecked}
                isDataFiltered={isDataFiltered}
                sortDirection={sortDirection}
                sortColumnsName={sortColumnsName}
                isShowFilter={isShowFilter}
                dataItemsSelected={selectedItems}
                columns={this.getColumns()}
                options={filterOptions}
                onItemClick={this.onItemClick}
                onSortClick={this.onTableSortClick}
                onApplyFilterClick={this.onApplyFilterClick}
                onClearFilterClick={this.onClearFilterClick}
                onTableFilterChange={this.onTableFilterChange}
                onTableFilterClick={this.onTableFilterClick}
                setCurrentPageParams={this.setCurrentPageParams}
                onSelectAllClick={this.onSelectAllClick}
                onSelectAllOnPageClick={this.onSelectAllOnPageClick}
                onUnselectAllClick={this.onUnselectAllClick}
                gridTitle={gridTitle}
                actionItems={[]}
                disableSelection={true}
                rowClassName="custom-table-row"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
