import * as React from 'react';
import { GridBody } from './GridBody';
import { ColumnDefinition, TABLE_SORT_DIRECTION, SelectOption } from 'Src/helpers/table';
import './Grid.css';
import { GridPanel } from './GridPanel';
import { ActionItem } from './ActionsButton/ActionsButton';
import { AppUser } from '../../views/App/App';
import { FunctionComponent } from 'react';
import { GridTitle } from './GridTitle';

interface Props {
  dataItems: any[];
  onItemClick: (index: number) => void;
  onSortClick: (sortField: string) => void;
  columns: ColumnDefinition[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  dataItemsSelected: any[];
  onApplyFilterClick: () => void;
  onClearFilterClick: () => void;
  actionItems: ActionItem[];
  currentPage: number;
  total: number;
  isSelectAllChecked: boolean;
  isDataFiltered: boolean;
  setCurrentPageParams: (currentPage: number) => void;
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  onTableFilterClick: (event) => void;
  onTableFilterChange?: (event, value: string, options?) => void;
  filters: any;
  goBack?: () => void;
  gridTitle?: string;
  isPDF?: boolean;
  isCSV?: boolean;
  isSelectAllDisabled?: boolean;
  user?: AppUser;
  options?: SelectOptions;
  autocompleteSearchFunctions?: any;
  autocompleteGetElementsTitles?: any;
  onTableAutocompleteFilterChange?: any;
  autocompleteDefaultTexts?: any;
  sumFields?: { field: string; text: string }[];
  className?: string;
  useCustomPagination?: boolean;
  disableSelection?: boolean;
  rowClassName?: string;
  disableCsvButton?: boolean;
}

export interface SelectOptions {
  schoolTypes?: SelectOption[];
  photoType?: SelectOption[];
  productType?: SelectOption[];
  userTypes?: SelectOption[];
  isSchoolDeliveryAvailable?: SelectOption[];
  isPreviewAvailable?: SelectOption[];
  isOutsidePackageAvailable?: SelectOption[];
  isDigitalProduct?: SelectOption[];
  orderStatus?: SelectOption[];
  shared?: SelectOption[];
  statusNotification?: SelectOption[];
  channelType?: SelectOption[];
  integrationLogStatus?: SelectOption[];
  requestStatus?: SelectOption[];
  promoEmail?: SelectOption[];
  isAutoAcceptAvailable?: SelectOption[];
  noAutoAcceptReason?: SelectOption[];
  isDataNotificationEnabled?: SelectOption[];
  isSchoolAdmin?: SelectOption[];
}

export const Grid: FunctionComponent<Props> = props => {
  const { className, useCustomPagination, disableSelection, rowClassName, disableCsvButton, ...restProps } = props;

  return (
    <>
      <GridPanel
        actionItems={props.actionItems}
        currentPage={props.currentPage}
        setCurrentPageParams={props.setCurrentPageParams}
        total={props.total}
        onSelectAllClick={props.onSelectAllClick}
        onSelectAllOnPageClick={props.onSelectAllOnPageClick}
        onUnselectAllClick={props.onUnselectAllClick}
        isSelectAllChecked={props.isSelectAllChecked}
        selectedItems={props.dataItemsSelected}
        onTableFilterClick={props.onTableFilterClick}
        isShowFilter={props.isShowFilter}
        isDataFiltered={props.isDataFiltered}
        goBack={props.goBack}
        gridTitle={props.gridTitle}
        isPDF={props.isPDF}
        isCSV={props.isCSV}
        columns={props.columns}
        filters={props.filters}
        isSelectAllDisabled={props.isSelectAllDisabled}
        sumFields={props.sumFields}
        user={props.user}
        useCustomPagination={useCustomPagination}
        disableSelection={disableSelection}
        disableCsvButton={disableCsvButton}
      />
      <div className={`table-responsive h-100 ${className}`}>
        <table className="table table-sm mt-3 mPre mVerticalAlignMiddle mVerticalAlignTop">
          <GridTitle
            sortColumnsName={props.sortColumnsName}
            sortDirection={props.sortDirection}
            columns={props.columns}
            onSortClick={props.onSortClick}
            isShowFilter={props.isShowFilter}
            onApplyFilterClick={props.onApplyFilterClick}
            onClearFilterClick={props.onClearFilterClick}
            filters={props.filters}
            options={props.options}
            onTableFilterChange={props.onTableFilterChange}
            autocompleteSearchFunctions={props.autocompleteSearchFunctions}
            autocompleteGetElementsTitles={props.autocompleteGetElementsTitles}
            onTableAutocompleteFilterChange={props.onTableAutocompleteFilterChange}
            autocompleteDefaultTexts={props.autocompleteDefaultTexts}
          />
          <GridBody
            dataItems={props.dataItems}
            columns={props.columns}
            dataItemsSelected={props.dataItemsSelected}
            onDataItemClick={props.onItemClick}
            user={props.user}
            disableSelection={disableSelection}
            rowClassName={rowClassName} // Pass rowClassName to GridBody
          />
        </table>
      </div>
    </>
  );
};

Grid.defaultProps = {
  isPDF: false,
  isCSV: true,
};

Grid.displayName = 'Grid';
