import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { CustomSwitch } from '../../../../components/CustomSwitch/CustomSwitch';
import { getNotificationsEnabledCount, getSchoolAdminsCount } from '../../../../services/schoolUser/schools';
import * as propz from 'propz';
import '../../appSchools2.css';

interface Props {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  user: any;
  schoolUser?: any;
}

export function SchoolAppSchoolUsersForm2(props: Props) {
  const { schoolUser, onSubmit, onCancel, user } = props;
  const isUserExist = !!schoolUser;

  const UserSchema = isUserExist
    ? Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .required('Required'),
        mobilePhone: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
      })
    : Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .required('Required'),
        password: Yup.string().required('Required'),
        mobilePhone: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
      });

  const initialValues = {
    email: schoolUser?.email || '',
    mobilePhone: schoolUser?.mobilePhone || '',
    firstName: schoolUser?.firstName || '',
    lastName: schoolUser?.lastName || '',
    isDataNotificationEnabled: schoolUser?.isDataNotificationEnabled || false,
    isSchoolAdmin: schoolUser?.isSchoolAdmin || false,
    password: '',
  };

  const [submitError, setSubmitError] = React.useState<string | null>(null);

  const handleFormSubmit = async (
    values: typeof initialValues,
    { setErrors, setSubmitting, setFieldValue }: FormikHelpers<typeof initialValues>
  ) => {
    const { isDataNotificationEnabled, isSchoolAdmin } = values;
    const errors: Partial<Record<keyof typeof initialValues, string>> = {};

    try {
      if (!isDataNotificationEnabled && schoolUser?.isDataNotificationEnabled) {
        const countObj = await getNotificationsEnabledCount(user, user.activeSchool.id);

        if (countObj.count === 1) {
          setFieldValue('isDataNotificationEnabled', true, false);
          errors.isDataNotificationEnabled =
            'You cannot turn off notifications for the only user with notifications enabled. Please enable notifications for another user first.';
        }
      }

      if (!isSchoolAdmin && schoolUser?.isSchoolAdmin) {
        const adminCountObj = await getSchoolAdminsCount(user, user.activeSchool.id);

        if (adminCountObj.count === 1) {
          setFieldValue('isSchoolAdmin', true, false);
          errors.isSchoolAdmin =
            'You cannot turn off the "School Admin" status for this user as they are currently the only school admin. To proceed, you must first assign another user as a school admin.';
        }
      }

      if (!isSchoolAdmin && schoolUser?.id === user.userId) {
        setFieldValue('isSchoolAdmin', true, false);
        errors.isSchoolAdmin = 'You cannot turn off your own "School Admin" status.';
      }

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }

      const { mobilePhone, password, ...rest } = values;
      const dataToSubmit = mobilePhone ? values : rest;
      onSubmit(dataToSubmit);
    } catch (error) {
      const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
      console.error('Error fetching count:', errorText);
      setSubmitError(errorText);
      setSubmitting(false);
    }
  };

  return (
    <div className="container-fluid custom-form-container">
      <div className="row">
        <div className="col-md-12">
          <Formik initialValues={initialValues} validationSchema={UserSchema} onSubmit={handleFormSubmit}>
            {({ handleSubmit, setFieldValue, errors, isSubmitting }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  <div className="form-group">
                    <label>Phone</label>
                    <Field name="mobilePhone" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="mobilePhone" />
                  </div>

                  <div className="form-group">
                    <label>First Name</label>
                    <Field name="firstName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
                  </div>

                  <div className="form-group">
                    <label>Last Name</label>
                    <Field name="lastName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <Field name="password" type="password" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="password" />
                  </div>

                  <Field name="isDataNotificationEnabled">
                    {({ field }) => (
                      <>
                        <CustomSwitch
                          checked={field.value}
                          onChange={() => setFieldValue('isDataNotificationEnabled', !field.value)}
                          label="Notifications"
                          customColor="#2c3e50"
                        />
                        <ErrorMessage
                          name="isDataNotificationEnabled"
                          component="div"
                          className="alert alert-danger mt-2"
                        />
                      </>
                    )}
                  </Field>

                  <Field name="isSchoolAdmin">
                    {({ field }) => (
                      <>
                        <CustomSwitch
                          checked={field.value}
                          onChange={() => setFieldValue('isSchoolAdmin', !field.value)}
                          label="School admin"
                          customColor="#2c3e50"
                        />
                        <ErrorMessage name="isSchoolAdmin" component="div" className="alert alert-danger mt-2" />
                      </>
                    )}
                  </Field>

                  {submitError && <div className="alert alert-danger">{submitError}</div>}

                  <div className="custom-button-container">
                    <button type="submit" className="custom-button" disabled={isSubmitting}>
                      <img src="/dist/images/icon/save.png" alt="Save" className="custom-edit-icon" />
                      Save
                    </button>
                    <button type="button" onClick={onCancel} className="custom-button">
                      <img src="/dist/images/icon/cancel.png" alt="Cancel" className="custom-edit-icon" />
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
