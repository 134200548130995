export const TABS = {
  SUMMARY: 'Summary',
  PRICE_SETS: 'Price sets',
  PERMISSIONS: 'Permissions',
  NOTIFICATION_CHANNELS: 'Notification channels',
  NOTIFICATIONS: 'Notifications',
  ADDRESS_BOOK: 'Address book',
  MY_DETAILS: 'My details',
  ORDER_HISTORY: 'Order history',
  SUBSCRIPTIONS: 'Subscriptions',
  SCHOOL_FORMS: 'School forms',
  PUPILS: 'Pupils',
  STUDENTS: 'Students',
  SCHOOL_DETAILS_CHANGE_LOGS: 'School details change logs',
  NOTES: 'Notes',
  ORDERS: 'Orders',
  USERS: 'Users',
};

export const YES_NO_OPTIONS = { YES: 'Yes', NO: 'No' };

export const CURRENCY_SYMBOL = {
  POUND: '£',
};

export const PHONE_CODE = {
  RU: '+7',
  UK: '+44',
};

export const MAX_COUNTRY_PHONE_CODE_LENGTH = 6;

export const DEFAULT_SLIDER_DELAY_IN_MILLISECONDS = 5000;
export const DEFAULT_SLIDER_DELAY_IN_SECONDS = 5;

export const MILLISECONDS_IN_SECOND = 1000;

export const NOT_AUTHORIZED_STATUS_CODE = 401;
export const CREATED_STATUS_CODE = 201;
export const SUCCESS_STATUS_CODE = 200;
export const NO_CONTENT_STATUS_CODE = 204;
export const GONE_STATUS_CODE = 410;
export const NOT_FOUND_STATUS_CODE = 404;

export const PLUS_REPLACEMENT = '_plus_';
