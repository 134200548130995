import * as React from 'react';
import { useEffect, useRef } from 'react';
import { FieldProps } from 'formik';

interface AutoResizingTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  className?: string;
}

const AutoResizingTextarea: React.FC<AutoResizingTextareaProps> = ({ value, onChange, name, className }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      name={name}
      value={value || ''}
      onChange={onChange}
      rows={1}
      className={className}
      style={{ overflow: 'hidden', resize: 'none', width: '100%' }}
    />
  );
};

export default AutoResizingTextarea;
