import * as React from 'react';
import { FunctionComponent } from 'react';
import * as propz from 'propz';
import { School } from '../../../../../../../models/schools';

interface Props {
  schoolItem: School;
}

const ROWS = [
  { title: 'Reference', field: 'reference' },
  { title: 'Name', field: 'name' },
  { title: 'Town', field: 'city' },
  { title: 'Email', field: 'email' },
  { title: 'Phone', field: 'phone' },
  { title: 'Address', field: 'schoolAddress' },
  { title: 'Site Location', field: 'siteLocation' },
  { title: 'LEA Number', field: 'leaNumber' },
  { title: 'DFES Number', field: 'dfesNumber' },
  { title: 'Database Type', field: 'databaseType' },
  { title: 'Bank Account Name', field: 'bankDetails.accountName' },
  { title: 'Sort Code', field: 'bankDetails.sortCode' },
  { title: 'Account Number', field: 'bankDetails.accountNumber' },
];

export const SchoolSummary: FunctionComponent<Props> = props => {
  const rows = ROWS.map((row, index) => {
    let contentCell: string;
    const field = row.field;
    const { schoolItem } = props;

    switch (field) {
      case 'reference':
        contentCell = propz.get(schoolItem, ['code'], '');
        break;
      case 'name':
        contentCell = propz.get(schoolItem, ['name'], '');
        break;
      case 'city':
        contentCell = propz.get(schoolItem, ['city'], '');
        break;
      case 'email':
        contentCell = propz.get(schoolItem, ['email'], '');
        break;
      case 'phone':
        contentCell = propz.get(schoolItem, ['phone'], '');
        break;
      case 'schoolAddress':
        contentCell = propz.get(schoolItem, ['schoolAddress'], '');
        break;
      case 'siteLocation':
        contentCell = propz.get(schoolItem, ['siteLocation'], '');
        break;
      case 'leaNumber':
        contentCell = propz.get(schoolItem, ['leaNumber'], '');
        break;
      case 'dfesNumber':
        contentCell = propz.get(schoolItem, ['dfesNumber'], '');
        break;
      case 'databaseType':
        contentCell = propz.get(schoolItem, ['databaseType'], '');
        break;
      case 'bankDetails.accountName':
        contentCell = propz.get(schoolItem, ['bankDetails', 'accountName'], '');
        break;
      case 'bankDetails.sortCode':
        contentCell = propz.get(schoolItem, ['bankDetails', 'sortCode'], '');
        break;
      case 'bankDetails.accountNumber':
        contentCell = propz.get(schoolItem, ['bankDetails', 'accountNumber'], '');
        break;
      default:
        contentCell = propz.get(schoolItem, [row.field], '');
    }

    return (
      <tr key={`school_summary_${index}`}>
        <td>{row.title}</td>
        <td>{contentCell}</td>
      </tr>
    );
  });

  return (
    <table className={'table table-sm mPre mt-3'}>
      <tbody>{rows}</tbody>
    </table>
  );
};

SchoolSummary.displayName = 'SchoolSummary';
