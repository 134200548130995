import * as React from 'react';
import * as Promise from 'bluebird';
import { Component } from 'react';
import {
  ColumnDefinition,
  convertToFilterWithPlus,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { CHECKBOX_FIELDS, DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { History, Location } from 'history';
import { imageCell } from '../../../../helpers/cell';
import { SchoolImageWithTicket } from '../../../../models/schoolImages';
import { SchoolJob } from '../../../../models/schoolJob';
import { getSchoolUserSchoolJob } from '../../../../services/schoolUser/schoolJobs';
import { AppSchoolsUser2 } from '../../AppSchools2';
import {
  createSchoolUserSchoolJobImageTicket,
  getSchoolUserAllSchoolJobImages,
  getSchoolUserSchoolJobImages,
  getSchoolUserSchoolJobImagesCount,
  updateSchoolUserSchoolJobImage,
} from '../../../../services/schoolUser/schoolJobImages';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { SchoolAppSchoolJobImageForm } from './SchoolAppSchoolJobImageForm/SchoolAppSchoolJobImageForm';
import { SCHOOL_JOB_IMAGES_TABS, SCHOOL_JOB_STATUS } from '../../../../consts/schoolJob';
import { TABS } from '../../../../consts/common';
import { SchoolAppSchoolJobImageSummary } from './SchoolAppSchoolJobImageSummary/SchoolAppSchoolJobImageSummary';
import { GenericSummary } from '../../../../components/GenericSummary/GenericSummary';
import { ResizablePanel } from '../../../../components/ResizablePanel/ResizablePanel';

// Base columns definition
const BASE_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Image',
    field: 'image',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: imageCell,
  },
  {
    text: 'Title',
    field: 'staffDataTitle',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['staffData', 'title'],
  },
  {
    text: 'Name',
    field: 'staffDataName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['staffData', 'name'],
  },
  {
    text: 'Surname',
    field: 'staffDataSurname',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['staffData', 'surname'],
  },
  {
    text: 'Job title',
    field: 'staffDataJobTitle',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['staffData', 'jobTitle'],
  },
];

interface State {
  items: SchoolImageWithTicket[];
  currentPage: number;
  selectedItems: SchoolImageWithTicket[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  job: SchoolJob;
  isJobImageFormModalOpen: boolean;
  currentTabIndex: number;
}

interface LocationState {
  jobId?: string;
  search?: string;
}

interface Props {
  user: AppSchoolsUser2;
  history: History;
  location: Location & { state: LocationState };
}

export class SchoolAppSchoolJobImages2 extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      job: undefined,
      isJobImageFormModalOpen: false,
      currentTabIndex: 0,
    };
  }

  getJobId(): string {
    const { history, location } = this.props;
    const search = parse(history.location.search);

    // First try to get the jobId from the state, fallback to the URL query
    const jobId = location.state?.jobId || (search.job as string);
    return jobId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setAdditionalItems().then(res => {
      if (this.state.job) {
        this.setItems();
      }
    });
  }

  setAdditionalItems() {
    const { user } = this.props;
    const jobId = this.getJobId();

    return getSchoolUserSchoolJob(user, jobId)
      .then(job => {
        if (!job) {
          throw new Error('Job not found');
        }

        this.setState({
          job: job,
          isLoading: false, // Stop loading once the job is fetched
        });

        return true;
      })
      .catch(error => {
        console.error('Error fetching job:', error);
        this.setState({ isLoading: false }); // Stop loading if there's an error
      });
  }

  getColumns(): ColumnDefinition[] {
    const { job } = this.state; // Access the job status from the state

    // Create a copy of the base columns
    const columns = [...BASE_COLUMNS];

    // Add the dynamic 'Actions' column
    columns.push({
      text: 'Actions',
      field: 'actions',
      isSort: false,
      cell: (item: SchoolImageWithTicket): JSX.Element => {
        const jobStatus = job?.status; // Dynamically access job status

        return (
          <td className="custom-action-cell">
            <button
              onClick={() => this.onEditImageClick(item)}
              className="custom-action-button-thumbnails"
              disabled={jobStatus !== SCHOOL_JOB_STATUS.NEW} // Enable/disable button based on job status
            >
              <img src="/dist/images/icon/edit.png" alt="Edit" className="custom-edit-icon" />
              Edit image
            </button>
          </td>
        );
      },
    });

    return columns; // Return the updated columns array
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(BASE_COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const jobId = this.getJobId();

    const getItemsPromise = getSchoolUserSchoolJobImages(user, jobId, serverQueryFilter);
    const getItemsCountPromise = getSchoolUserSchoolJobImagesCount(user, jobId, where);

    const promises = [getItemsCountPromise, getItemsPromise];
    let countObj, items;
    return Promise.all(promises)
      .then(([_countObj, _items]) => {
        countObj = _countObj;
        items = _items;

        return Promise.all(
          items.map(item => {
            const data = {
              key: item.key,
              mimeType: item.mimeType,
            };

            return createSchoolUserSchoolJobImageTicket(user, data);
          })
        );
      })
      .then(tickets => {
        const imagesExtended = items.map((item, index) => ({
          ...item,
          ticket: tickets[index],
        }));

        const { selectedItems } = this.state;
        let updatedSelectedItem;
        if (selectedItems.length === 1) {
          updatedSelectedItem = imagesExtended.find(image => image.id === selectedItems[0].id);
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: imagesExtended,
            selectedItems: [updatedSelectedItem],
            total: countObj.count,
            basePath: history.location.pathname,
            isLoading: false,
          });
        } else {
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: imagesExtended,
            total: countObj.count,
            basePath: history.location.pathname,
            isLoading: false,
          });
        }
        return true;
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const jobId = this.getJobId();

    getSchoolUserAllSchoolJobImages(user, jobId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
        case CHECKBOX_FIELDS.CHECKBOX: {
          const checked = event.target.checked;
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              value: '',
              checked: checked,
            },
          };
          break;
        }
        case CHECKBOX_FIELDS.TEXT:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              value: filterValue,
            },
          };
          break;
      }
    } else {
      // Use BASE_COLUMNS instead of COLUMNS
      const filter = BASE_COLUMNS.find(col => col.field === filterField);
      const filterType = filter?.type; // Use optional chaining to avoid errors if filter is undefined

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}`,
      state: history.location.state,
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const jobId = this.getJobId();

    history.push({
      pathname: '/schoolJobs',
      search: search,
      state: { jobId: jobId },
    });
  };

  onEditImageClick = (item: SchoolImageWithTicket) => {
    this.setState({
      selectedItems: [item], // Set the clicked item as the selected item
      isJobImageFormModalOpen: true,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isJobImageFormModalOpen: false,
    });
  };

  onSubmitForm = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    const jobId = this.getJobId();

    this.setState({
      isLoading: true,
    });

    const image = selectedItems[0];
    const { id } = image;

    let imageExtended;

    updateSchoolUserSchoolJobImage(user, jobId, id, data)
      .then(image => {
        imageExtended = image;
        return createSchoolUserSchoolJobImageTicket(user, image);
      })
      .then(ticket => {
        imageExtended = { ...imageExtended, ticket };
        this.setState({
          isJobImageFormModalOpen: false,
          selectedItems: [imageExtended],
          isSelectAllChecked: false,
        });
        this.setItems();
      });
  };

  renderFormModal(): React.ReactNode {
    const { isJobImageFormModalOpen, selectedItems } = this.state;
    const customModalClass = 'custom-user-modal';

    const image = selectedItems[0]; // Safely access the selected image

    return (
      <SimpleModal isOpen={isJobImageFormModalOpen} customClass={customModalClass}>
        <SchoolAppSchoolJobImageForm image={image} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
      </SimpleModal>
    );
  }

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      job,
    } = this.state;

    // If job is undefined, show a loading indicator or a message
    if (!job) {
      return <Loader />; // Or any other loading indicator or message you prefer
    }

    const actionItems = [
      {
        itemText: 'Edit image',
        onItemClick: this.onEditImageClick,
        isActive: selectedItems.length === 1 && job.status === SCHOOL_JOB_STATUS.NEW,
      },
    ];

    const filterOptions = {};

    const gridTitle = `Job ${job.jobReference} / Images`;

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={this.getColumns()}
        actionItems={[]}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
        rowClassName="custom-table-row"
        disableCsvButton={true}
      />
    );
  }

  getTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    const tabs = SCHOOL_JOB_IMAGES_TABS;
    const currentTab = tabs[currentTabIndex];
    const [image] = selectedItems;

    switch (currentTab) {
      case TABS.SUMMARY:
        return <SchoolAppSchoolJobImageSummary image={image} />;
      default:
        return <div />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index,
    });
  };

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a image above</div>;
    }

    const tabs = this.getTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={SCHOOL_JOB_IMAGES_TABS} currentTabIndex={currentTabIndex}>
        {tabs}
      </GenericSummary>
    );
  }

  render() {
    const { isLoading, isJobImageFormModalOpen, job } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (!job) {
      return <div>No job found. Please go back and select a job.</div>;
    }

    const classes = isJobImageFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={`${classes} schoolAppContentWrapper`}>
        {' '}
        {/* Add schoolAppContentWrapper here */}
        {this.renderFormModal()}
        <div className="row">
          <div className="col-md-12">
            {/* Apply the resizable-panel and other styles */}
            <div className="resizable-panel">
              <ResizablePanel>
                {this.renderGrid()}
                {this.renderSummary()}
              </ResizablePanel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
